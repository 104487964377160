.main-login{
    margin: 10% auto;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.login-content{
   
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 2rem;
    width: 300px;
}

.login-itens{
    width: 100%;

}

.login-error{
    text-align: center;
    color: red;
}