/* Header */
.header{
    background-color: #f4f4f4;
    box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-items: center;
}

.header div[tag="menu"] svg{

    width: 45px;
    height: 45px;
    color: var(--primaria);
    cursor: pointer;
}

.header div[tag="menu"] svg:hover{
    color: var(--secundaria);
}

.header-menuBar {
    border-radius: 0;
    margin: 0;
    width: 25%;
    height: 100%;
    right: 0;
    position: absolute;
}

.dados-gerais{
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    gap: 20px;
    padding-top: 1rem;
}

.dados-gerais span{
    font-family: 'Roboto';
}






/* Menu */
.menuList{
    list-style: none;
    display: grid;
    gap: 20px;
    margin-top: 1rem;
}

.menuList li{
    cursor: pointer;
    font-family: 'Roboto';
    padding: 0.2rem;
    border-radius: 5px;
    padding-left: 0.4rem;
    
}

.menuList li:hover{
    background-color: var(--terciaria);
    font-weight: bolder;
    color: var(--letra);
}









/* Main */
.main{
    max-width: 1200px;
    margin: auto;
    padding: 0.4rem 0;
}

.main-pesquisa{
    display: flex;
    gap: 20px;
    align-items: center;
}

.main-pesquisa button{
    white-space: nowrap;
    height: 30px;
    line-height: 1;
}


.list{
    padding-top: 1rem;
    gap: 10px;
    display: grid;
}

.list-Users{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d3d3d3;
    padding: 0.2rem;
    border-radius: 6px;
    cursor: pointer;
}

.list-Users:hover{
    background-color: #f4f4f4;
}


.picture-Users{
    flex: 28% 1 1;
    display: flex;
    align-items: center;
    gap: 6px;
}


.whats-Users{
    flex: 18% 1 1;
    text-align: center;
}


.status-Users{
    flex: 18% 1 1;
    text-align: center;
}


.ass-Users{
    flex: 18% 1 1;
    text-align: center;
}


.afiado-Users{
    flex: 18% 1 1;
    text-align: end;
}



.assinatura-users{
    background-color: #8080807a;
    font-family: 'Open Sans';
    padding: 0.2rem 0.3rem 0.25rem 0.3rem;
    border-radius: 4px;
    color: #f4f4f4;
    box-shadow: 0 8px 146z\'px 0 rgb(31 38 135 / 10%);
}


.assinatura-users[tag="Em Dia"]{
    background-color: #008069;
}

.assinatura-users[tag="Inadimplente"]{
    background-color: red;
}

.nav-list{
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-list button{
    display: flex;
    align-items: center;
    gap: 5px;
}

.disableMove{
    background-color: #808080 !important;
    cursor: not-allowed;
}

.quantClient{
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: bolder;
    font-family: 'Roboto';
}










/* UserCard */
.card-modal{
    width: 900px;
}

.card-content{
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.card-view{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 20% 1 1;
}

.trash-card{
    width: 100%;
    cursor: pointer;
}

.trash-card svg{
    color: var(--btnNot);
    width: 20px;
    height: 20px;
    display: block;
}

.card-ass{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-ass svg{
    width: 30px;
    color: #008069;
    height: 30px;
    display: inline-block;
}

.card-ass [tag="assinatura"]{
    font-size: 1.4rem;
    font-family: 'Acme';
    color: var(--textColor);
    text-align: center;
}


.card-ass [tag="valor"]{
    display: flex;
    gap: 8px;
    font-size: 1.1rem;
    font-family: 'Roboto';
    font-weight: 600;
    align-items: center;
}

.card-dados{
    flex: 50% 1 1;
    font-family: 'Roboto';
    gap: 11px;
    display: grid;
}


.data-main{
    display: flex;
    width: 50px;
    gap: 7px;
}

.data-input{
    text-align: center;
    padding: 0;
}

.data-separador{
    font-size: 1.7rem;
    font-weight: 300;
}


.card-modulos{
    flex: 30% 1 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.card-modulos h2{
    text-align: center;
}

.card-modulos div{
    display: flex;
    justify-content: space-between;
}

.card-close{
    position: absolute;
    right: 15px;
    top: 0;
}

.label-instancias{
    width: 60px;
}

.label-instancias input{
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
}




/* List Afiliados */
.afi-nome{
    flex: 20% 1 1;
    text-align: initial;
}

.afi-senha{
    flex: 20% 1 1;
    text-align: center;
}

.afi-walet{
    flex: 40% 1 1;
    text-align: center;
}

.afi-percentage{
    flex: 20% 1 1;
    text-align: end;
}


.create-afiliate{
    gap: 13px;
    display: grid;
    padding-top: 0.5rem;
}


.create-afiliate label{
    font-size: 1.2rem;
    font-family: 'Open Sans';
    color: var(--textColor);
    font-weight: 700;
}


.create-afiliate p{
    font-size: 0.8rem;
}


